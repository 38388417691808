import { signal } from '@preact/signals-react'
import { useEffect, useRef, useState } from 'react'
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg'
import { LayerFeature } from 'types'
import InfoPanel from './info/InfoPanel'

import './PanelsManager.scss'

/**
 * Signal used to communicate with right panel
 * which is responsible for displaying/hiding the content
*/
export const PanelSignal = {
  title: signal<string>(''),
  content: signal<React.ReactNode>(),
}

export const MapDataSignal = {
  hoveredObjects: signal<LayerFeature[]>(),
  clickedObjects: signal<LayerFeature[]>(),
  lineFilter: signal<string>(''),
  opacity: 0.5,
}

export default function PanelsManager() {
  const managerRef = useRef<HTMLDivElement>(null)
  const { title, content } = PanelSignal
  const objectInfo = MapDataSignal.clickedObjects.value
  const [displayed, setDisplayed] = useState(false)

  const handleClose = () => {
    setDisplayed(false)
  }

  useEffect(() => {
    if (!objectInfo?.at(0)?.properties) return
    const { libelle } = objectInfo.at(0).properties
    title.value = libelle || 'N/C'
    content.value = <InfoPanel />
  }, [objectInfo])

  useEffect(() => {
    if (!content.value) return
    setDisplayed(true)
  }, [content.value])

  useEffect(() => {
    if (!managerRef.current) return undefined

    const handleTransitionEnd = () => {
      if (displayed) return
      title.value = ''
      content.value = undefined
    }

    managerRef.current.addEventListener('transitionend', handleTransitionEnd)
    return () => managerRef?.current?.removeEventListener('transitionend', handleTransitionEnd)
  }, [managerRef.current, displayed])

  return (
    <div ref={managerRef} className={`panels-manager hide-scroll${displayed ? ' displayed' : ' hidden'}`}>
      <CloseIcon className="close" onClick={handleClose} />
      <h3>{title.value}</h3>
      {content.value}
    </div>
  )
}
