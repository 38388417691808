import { useEffect, useState } from 'react'
import { CHARTIS_SOURCES_IDS } from 'services'
import { MapDataSignal } from '../PanelsManager'

import './InfoPanel.scss'

export default function InfoPanel() {
  const objectInfo = MapDataSignal.clickedObjects.value
  const [data, setData] = useState<{line: string, voie: string, pk: string, type: string}>()

  const TYPE_PROPERTIES = {
    [CHARTIS_SOURCES_IDS.signal]: 'type_installation_fixe_id_libelle',
    [CHARTIS_SOURCES_IDS.localisateur]: 'type_installation_fixe_id_libelle',
    [CHARTIS_SOURCES_IDS.passageNiveau]: 'type_installation_fixe_id_libelle',
    [CHARTIS_SOURCES_IDS.acces]: 'type_installation_fixe_id_libelle',
    [CHARTIS_SOURCES_IDS.dbc]: 'type_installation_fixe_id_mnemo',
    [CHARTIS_SOURCES_IDS.pedale]: 'type_installation_fixe_id_mnemo',
    [CHARTIS_SOURCES_IDS.sousStation]: 'type_electrifications_id_libelle',
    [CHARTIS_SOURCES_IDS.adv]: 'type_appareil_de_voie_id_mnemonique',
    [CHARTIS_SOURCES_IDS.advLine]: 'type_appareil_de_voie_id_mnemonique',
  }

  const getTypeProperty = (properties: {[key: string]: string}, source: string) => {
    if (!Object.keys(TYPE_PROPERTIES).includes(source)) return ''
    return Object.entries(properties).find(([key]) => key === TYPE_PROPERTIES[source])?.[1] || 'NC'
  }

  useEffect(() => {
    if (!objectInfo?.at(0)?.properties || !objectInfo?.at(0)?.layer) return

    const { source } = objectInfo.at(0).layer
    const { lrs_ligne: line, lrs_voie: voie, lrs_pk: pk } = objectInfo.at(0).properties
    const type = getTypeProperty(objectInfo.at(0).properties, source?.toString())

    setData({ line, voie, pk, type })
  }, [objectInfo])

  return (
    <div className="info-panel panel">
      <div className="divider" />
      <div className="header">
        <p>Infos</p>
        <div className="menu" />
      </div>
      <div className="content">
        {data && (
          <div className="info">
            <p>
              <b>Ligne : </b>
              {data.line || 'N/C'}
            </p>
            <p>
              <b>Voie : </b>
              {data.voie || 'N/C'}
            </p>
            <p>
              <b>PK : </b>
              {data.pk || 'N/C'}
            </p>
            {data.type && (
            <p>
              <b>Type : </b>
              {data.type || 'N/C'}
            </p>
            )}
          </div>
        )}
      </div>
    </div>
  )
}
