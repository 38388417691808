import { MutableRefObject } from 'react'
import { MapRef } from 'react-map-gl'
import { CHARTIS_SOURCES_IDS, isLayerSelected, isSomeChildSelected } from 'services'
import {
  AccesLayer, ADVLayer, ADVLineLayer, BifLayer, CDVLayer, CSSLayer, DBCLayer,
  ExploitationModeLayer,
  JDZLayer,
  LocalisateurLayer, PassageNiveauLayer, PedaleLayer, RacLayer, SignalLayer, SousStationLayer, StationLayer, TrackLayer,
} from './layers'
import EditorLayer from './layers/editor/EditorLayer'

interface Props {
  mapRef?: MutableRefObject<MapRef>
}
export default function SchLayers({ mapRef }: Props) {
  return (
    <>
      <ExploitationModeLayer
        mapRef={mapRef}
        view="full_rgi_track_sch_flat"
        visibility={isLayerSelected('exploitation-mode-unique')
          || isLayerSelected('exploitation-mode-banalisee') || isLayerSelected('exploitation-mode-ipcs')}
      />
      <TrackLayer
        mapRef={mapRef}
        source={CHARTIS_SOURCES_IDS.trackSch}
        bpolygonParam="bpolygon"
      />
      <ADVLineLayer
        mapRef={mapRef}
        view="full_rgi_track_sch_flat"
        visibility={isLayerSelected('adv')}
      />
      <CDVLayer
        mapRef={mapRef}
        view="full_rgi_track_sch_flat"
        visibility={isLayerSelected('cdv')}
      />
      <RacLayer
        mapRef={mapRef}
        view="full_rgi_track_sch_flat"
        visibility={isLayerSelected('rac')}
      />
      <ADVLayer
        mapRef={mapRef}
        view="full_rgi_track_sch_flat"
        visibility={isLayerSelected('adv')}
      />
      <LocalisateurLayer
        mapRef={mapRef}
        view="full_rgi_track_sch_flat"
        visibility={isLayerSelected('localisateur')}
      />
      <PedaleLayer
        mapRef={mapRef}
        view="full_rgi_track_sch_flat"
        visibility={isLayerSelected('pedale')}
      />
      <AccesLayer
        mapRef={mapRef}
        view="full_rgi_track_sch_flat"
        visibility={isLayerSelected('acces')}
      />
      <BifLayer
        mapRef={mapRef}
        view="full_rgi_track_sch_flat_centroid"
        visibility={isLayerSelected('bif')}
      />
      <DBCLayer
        mapRef={mapRef}
        view="full_rgi_track_sch_flat"
        visibility={isLayerSelected('dbc')}
        angle="angle_rgi_track_sch_flat"
      />
      <PassageNiveauLayer
        mapRef={mapRef}
        view="full_rgi_track_sch_flat"
        visibility={isLayerSelected('passageNiveau')}
        angle="angle_rgi_track_sch_flat"
      />
      <JDZLayer
        mapRef={mapRef}
        view="full_rgi_track_sch_flat"
        visibility={isLayerSelected('jdz')}
        angle="angle_rgi_track_sch_flat"
      />
      <StationLayer
        mapRef={mapRef}
        view="full_rgi_track_sch_flat_centroid"
        visibility={isLayerSelected('station')}
      />
      <SousStationLayer
        mapRef={mapRef}
        view="full_rgi_track_sch_flat"
        visibility={isLayerSelected('sousStation')}
      />
      <CSSLayer
        mapRef={mapRef}
        view="full_rgi_track_sch_flat"
        visibility={isLayerSelected('css')}
      />
      <SignalLayer
        mapRef={mapRef}
        view="full_rgi_track_sch_flat"
        visibility={isSomeChildSelected('Signaux')}
        angle="angle_rgi_track_sch_flat"
      />
      <EditorLayer />
    </>
  )
}
