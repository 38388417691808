import { Signal, signal } from '@preact/signals-react'
import { FeatureOf, Polygon } from '@nebula.gl/edit-modes'
import { get } from '@osrdata/app_core/dist/requests'
import { DrawPolygonMode, EditingMode, RENDER_STATE, SHAPE } from 'react-map-gl-draw'
import { Feature, FeatureCollection } from 'geojson'
import { ContentType, Perimeter } from 'types'
import { ViewportProps } from 'react-map-gl'
import { ContentSignals } from 'pages/home/contents/ContentsManager'
import { calculateGeometryViewport, reprojectViewport } from './map'

type EditorMode = DrawPolygonMode | EditingMode

export const PerimeterSignals = {
  features: signal<FeatureOf<Polygon>[]>(null),
  mode: signal<EditorMode>(null),
  list: signal<Perimeter[]>([]),
  activePerimeter: signal<Perimeter | null>(null),
}

export const startPerimeterDraw = () => {
  PerimeterSignals.mode.value = new DrawPolygonMode()
  PerimeterSignals.features.value = []
}

export const updatePerimeterFeatures = (features: FeatureOf<Polygon>[]) => {
  PerimeterSignals.features.value = features
}

export const perimeterAddFeature = () => {
  PerimeterSignals.mode.value = new EditingMode()
}

export const cancelPerimeterDraw = () => {
  PerimeterSignals.mode.value = null
  PerimeterSignals.features.value = null
}

// STYLES

const CIRCLE_RADIUS = 8

type Style = {
  stroke?: string
  strokeWidth?: number
  fill?: string
  fillOpacity?: number
  r?: number
  strokeDasharray?: string
  x?: number
  y?: number
  height?: number
  width?: number
  cursor?: string
}

interface EditorFeatureStyleProps {
  feature: Feature
  state: RENDER_STATE
}

export function editorFeatureStyle({ feature, state }: EditorFeatureStyleProps) {
  const type = feature.properties?.shape || feature.geometry.type
  const style: Style = {
    stroke: '#000000',
    strokeWidth: 2,
    fill: '#445063',
    fillOpacity: 0.4,
    cursor: 'pointer',
  }

  if (state === RENDER_STATE.HOVERED) {
    style.strokeDasharray = '4,2'
    style.fillOpacity = 0.3
  } else if ([RENDER_STATE.UNCOMMITTED, RENDER_STATE.CLOSING, RENDER_STATE.INACTIVE].includes(state)) {
    style.strokeDasharray = '4,2'
    style.fillOpacity = 0.2
  }

  switch (type) {
    case SHAPE.POINT:
      style.r = CIRCLE_RADIUS
      break
    case SHAPE.LINE_STRING:
      style.fill = 'none'
      break
    case SHAPE.POLYGON:
      if (state === RENDER_STATE.CLOSING) {
        style.strokeDasharray = '4,2'
      }

      break
    case SHAPE.RECTANGLE:
      if (state === RENDER_STATE.UNCOMMITTED) {
        style.strokeDasharray = '4,2'
      }

      break
    default:
  }

  return style
}

interface EditHandleStyleProps {
  feature: Feature
  shape: 'circle' | 'rect'
  index: number
  state: RENDER_STATE
}

export function editHandleStyle({ shape, index, state }: EditHandleStyleProps) {
  const style: Style = {
    stroke: '#000000',
    strokeWidth: 1,
    fill: '#ffffff',
    cursor: 'pointer',
  }

  if (state === RENDER_STATE.HOVERED || index === -1) {
    style.fill = '#cccccc'
  }

  if (shape === 'circle') {
    style.r = CIRCLE_RADIUS
  }

  return style
}

export const getPerimeters = async () => {
  const featuresCollection: FeatureCollection = await get('/dexcarto/users/study-perimeters/')
  PerimeterSignals.list.value = featuresCollection?.features as Perimeter[]
}

const updateViewport = async (data: Signal<ContentType>, perimeter: Perimeter) => {
  if (!data.value || !['geo', 'sch'].includes(data.value.type)) return
  const { width, height } = data.value.vp
  if (!width || !height) return
  const { latitude, longitude, zoom } = calculateGeometryViewport(perimeter.geometry, { width, height })
  const viewport: Partial<ViewportProps> = { ...data.value.vp, latitude, longitude, zoom }
  if (data.value.type === 'sch') {
    data.value = { ...data.value, vp: viewport }
  } else {
    const geoViewport: Partial<ViewportProps> = await reprojectViewport(viewport, 'sch', 'geo')
    data.value = { ...data.value, vp: geoViewport }
  }
}

export const updatePerimeterViewport = async (perimeter: Perimeter) => {
  const leftData = ContentSignals.left.data
  const rightData = ContentSignals.right.data

  updateViewport(leftData, perimeter)
  updateViewport(rightData, perimeter)
}
